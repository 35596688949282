import { postData } from '../Services';

export const CARGA_SERVICE_PATHS = {
  PLANES_DE_ESTUDIO: '/libro-matriz/uploadStudyPlan',
  MATERIAS: '/libro-matriz/uploadCurricularBoxes'
};

export const uploadFile = async (path, file) => {
  const formData = new FormData();
  formData.append('file', file);
  let response = await postData(path, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response;
};
