import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomYellowButton } from '../../../../components/CustomYellowButton';
import { Actions } from '../../../../constants/Actions';
import { UsersContext } from '../../../../context/admin/users/UsersContext';
import { useAuthHelper } from '../../../../hooks/useAuthHelper';
import { AdminRouteConstants } from '../../../../routes/RoutesConstants';

export const UsersFilter = () => {
  const [stateInput, setStateInput] = useState('');
  const { authorizeElement } = useAuthHelper();
  const { search } = useContext(UsersContext);
  const navigate = useNavigate();
  const handleInput = (event) => setStateInput(event.target.value);
  const handleSearch = (event) => {
    event.preventDefault();
    search(stateInput);
  };

  return (
    <form onSubmit={handleSearch}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            autoComplete="off"
            id="outlined-required"
            label="Nombre, apellido, mail o CUIL"
            fullWidth
            margin="normal"
            value={stateInput}
            onChange={handleInput}
          />
        </Grid>
        <Grid item xs={2}>
          <Box mt={3}>
            <Button disabled={stateInput.length < 1} variant="contained" size="large" fullWidth type="submit">
              Buscar
            </Button>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box mt={3}>
            {authorizeElement(
              <CustomYellowButton title={'+ Agregar Usuario'} action={() => navigate(AdminRouteConstants.USUARIO_CREAR)} />,
              [Actions.CREATE_USER]
            )}
          </Box>
        </Grid>
      </Grid>
      <br />
    </form>
  );
};
