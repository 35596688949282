import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import { AccordionButton } from '../../../components/Accordion';
import PropTypes from 'prop-types';
import { Complete, Observed } from '../../../styles/Styles';

const ObservationsAccordion = ({ observations, onEdit, onDelete, onSend }) => {
  return (
    <AccordionButton
      title={<Typography sx={{ fontSize: '21px', fontWeight: '600' }}>Observaciones realizadas</Typography>}
      body={
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Fecha</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Observaciones</Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {observations?.map((observation) => (
              <TableRow key={observation.id}>
                <TableCell>
                  {new Date(observation.last_update).toLocaleString('es-ES', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  })}
                </TableCell>
                <TableCell>{observation.descriptions}</TableCell>
                <TableCell>{observation.is_sent ? <Complete>ENVIADA</Complete> : <Observed>SIN ENVIAR</Observed>}</TableCell>
                <TableCell>
                  {!observation.is_sent ? (
                    <>
                      <Tooltip title="Enviar">
                        <IconButton onClick={onSend}>
                          <SendIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Editar">
                        <IconButton onClick={onEdit}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Eliminar">
                        <IconButton onClick={onDelete}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      }
    />
  );
};

ObservationsAccordion.prototype = {
  observations: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSend: PropTypes.func
};

export default ObservationsAccordion;
