import { useState } from 'react';
import { CargaContext } from './CargaContext';
import { CARGA_SERVICE_PATHS, uploadFile } from '../../../services/admin/cargaService';
import Toast from '../../../components/Toast';
import XLSX from 'xlsx';

const SHEET_TITLES = ['Espacios curriculares', 'Planes de estudio'];
const STUDY_PLAN_CONFIG = [
  { name: 'miescuela_plan_id', width: 17 },
  { name: 'miescuela_plan_desc', width: 20 },
  { name: 'miescuela_cue', width: 15 },
  { name: 'sited_plan_desc', width: 20 },
  { name: 'sited_plan_id', width: 15 },
  { name: 'sited_titulo', width: 15 },
  { name: 'sited_normajurisdic', width: 20 },
  { name: 'sited_resolucion', width: 20 },
  { name: 'error', width: 70 }
];
const CURRICULAR_BOX_CONFIG = [
  { name: 'id_study_plan_sited', width: 20 },
  { name: 'miescuela_id_esp_curricular', width: 27 },
  { name: 'sited_id_esp_curricular', width: 23 }
];
const COLUMN_CONFIGS = [CURRICULAR_BOX_CONFIG, STUDY_PLAN_CONFIG];

const CargaState = ({ children }) => {
  const [studyPlanFile, setStudyPlanFile] = useState();
  const [curricularBoxFile, setCurricularBoxFile] = useState();
  const [invalidStudyPlanRecords, setInvalidStudyPlanRecords] = useState([]);
  const [invalidCurricularBoxRecords, setInvalidCurricularBoxRecords] = useState([]);

  const uploadStudyPlanFile = async () => {
    if (studyPlanFile) {
      try {
        const response = await uploadFile(CARGA_SERVICE_PATHS.PLANES_DE_ESTUDIO, studyPlanFile);
        if (response.data.invalidRecords.length > 0) setInvalidStudyPlanRecords(response.data.invalidRecords);
        Toast.success('Diccionario de plan de estudios subido correctamente.');
      } catch {
        Toast.error('Error al subir el diccionario plan de estudios.');
      }
    }
  };

  const uploadCurricularBoxFile = async () => {
    if (curricularBoxFile) {
      try {
        const response = await uploadFile(CARGA_SERVICE_PATHS.MATERIAS, curricularBoxFile);
        if (response.data.invalidRecords.length > 0) setInvalidCurricularBoxRecords(response.data.invalidRecords);
        Toast.success('Diccionario de espacios curriculares subidos correctamente.');
      } catch {
        Toast.error('Error al subir el diccionario de espacios curriculares.');
      }
    }
  };

  const uploadAllFiles = async () => {
    await uploadStudyPlanFile();
    await uploadCurricularBoxFile();
  };

  const generateExcelSheets = (workbook) => {
    [invalidCurricularBoxRecords, invalidStudyPlanRecords].forEach((records, index) => {
      if (records.length > 0) {
        const HEADER_CONFIG = COLUMN_CONFIGS[index].map((header) => header.name);
        const COL_WIDTHS = COLUMN_CONFIGS[index].map((config) => ({
          wch: config.width
        }));
        const parsedRecords = records.map((record) => ({
          ...record.row,
          error: record.error
        }));
        const sheet = XLSX.utils.json_to_sheet(parsedRecords, { header: HEADER_CONFIG });
        sheet['!cols'] = COL_WIDTHS;
        XLSX.utils.book_append_sheet(workbook, sheet, SHEET_TITLES[index]);
      }
    });
  };

  const generateExcelContent = () => {
    const workbook = XLSX.utils.book_new();
    generateExcelSheets(workbook);
    return XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
  };

  const downloadExcel = (content) => {
    const blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Listado de registros inválidos-${new Date().toLocaleDateString()}.xlsx`;
    link.click();
  };

  const handleExcelDownload = () => {
    const content = generateExcelContent();
    downloadExcel(content);
  };

  return (
    <CargaContext.Provider
      value={{
        setStudyPlanFile,
        setCurricularBoxFile,
        uploadAllFiles,
        studyPlanFile,
        curricularBoxFile,
        invalidStudyPlanRecords,
        invalidCurricularBoxRecords,
        handleExcelDownload
      }}>
      {children}
    </CargaContext.Provider>
  );
};

export default CargaState;
