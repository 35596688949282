import { Box, Button, Stack, ThemeProvider, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Actions } from '../constants/Actions';
import { useAuthHelper } from '../hooks/useAuthHelper';
import {
  AdminRouteConstants,
  EstablishmentRouteConstants,
  StudyPlanRouteConstants,
  // PrintAnalyticRouteConstants,
  // Control,
  HistoricalRouteConstants
} from '../routes/RoutesConstants';
import { themeSidebar } from '../styles/Styles';
import { deleteLocalStorageUnnecesaryItems } from '../helpers/deleteLocalStorageUnnecesaryItems';
import { VALIDADOR } from '../constants/Roles';

export const SidebarNav = () => {
  const auth = useAuthHelper();
  const isUserAdminOrLegalization = auth.isAllowed([Actions.READ_GLOBAL_STUDYPLAN]);

  const userData = JSON.parse(localStorage.getItem('user'));
  const isValidador = userData && userData.roles && userData.roles.length > 0 ? userData.roles[0] == VALIDADOR : false;
  const numberEstablishment = JSON.parse(localStorage.getItem('user'))?.establishments?.length >= 2;

  const validatorEstablishment = numberEstablishment && isValidador;

  return (
    <Grid sx={{ mt: 2, mb: 1 }}>
      <Box sx={{ height: '40rem', width: '200px' }}>
        <ThemeProvider theme={themeSidebar}>
          <Stack spacing={2} sx={{ maxWidth: 'max-content' }} onClick={deleteLocalStorageUnnecesaryItems}>
            <>
              <NavLink to="/" end style={{ textDecoration: 'none', marginTop: '0' }}>
                {({ isActive }) =>
                  isActive ? (
                    <Button
                      color="neutral"
                      variant="contained"
                      fullWidth
                      sx={{ justifyContent: 'flex-start', boxShadow: 'none', textTransform: 'none' }}>
                      Inicio / Ayuda
                    </Button>
                  ) : (
                    <Button color="neutral" variant="text" fullWidth sx={{ justifyContent: 'flex-start', textTransform: 'none' }}>
                      Inicio / Ayuda
                    </Button>
                  )
                }
              </NavLink>
              {auth.isAllowed([Actions.SELECT_ESTABLISHMENT]) || auth.isSuperAdmin() ? (
                <NavLink to={EstablishmentRouteConstants.BUSCAR} style={{ textDecoration: 'none', marginTop: '0' }}>
                  {({ isActive }) =>
                    isActive ? (
                      <Button
                        color="neutral"
                        variant="contained"
                        fullWidth
                        sx={{ justifyContent: 'flex-start', boxShadow: 'none', textTransform: 'none' }}>
                        Establecimiento
                      </Button>
                    ) : (
                      <Button color="neutral" variant="text" fullWidth sx={{ justifyContent: 'flex-start', textTransform: 'none' }}>
                        Establecimiento
                      </Button>
                    )
                  }
                </NavLink>
              ) : null}
              {auth.isAllowed([Actions.READ_GLOBAL_STUDYPLAN, Actions.READ_STUDYPLAN]) ? (
                <NavLink
                  to={
                    isUserAdminOrLegalization || validatorEstablishment
                      ? StudyPlanRouteConstants.ESTABLECIMIENTOS
                      : StudyPlanRouteConstants.BUSCAR
                  }
                  style={{ textDecoration: 'none', marginTop: '0' }}>
                  {({ isActive }) =>
                    isActive ? (
                      <Button
                        color="neutral"
                        variant="contained"
                        fullWidth
                        sx={{ justifyContent: 'flex-start', boxShadow: 'none', textTransform: 'none' }}>
                        Plan de estudios
                      </Button>
                    ) : (
                      <Button color="neutral" variant="text" fullWidth sx={{ justifyContent: 'flex-start', textTransform: 'none' }}>
                        Plan de estudios
                      </Button>
                    )
                  }
                </NavLink>
              ) : null}
              {auth.isAllowed([Actions.READ_ANALYTIC, Actions.READ_ANALYTIC_GROUPED]) ? (
                <NavLink to={auth.redirectAnalyticModule()} style={{ textDecoration: 'none', marginTop: '0' }}>
                  {({ isActive }) =>
                    isActive ? (
                      <Button
                        color="neutral"
                        variant="contained"
                        fullWidth
                        sx={{ justifyContent: 'flex-start', boxShadow: 'none', textTransform: 'none' }}>
                        Analíticos
                      </Button>
                    ) : (
                      <Button color="neutral" variant="text" fullWidth sx={{ justifyContent: 'flex-start', textTransform: 'none' }}>
                        Analíticos
                      </Button>
                    )
                  }
                </NavLink>
              ) : null}

              {/* {auth.isAllowed([Actions.READ_PRINT]) ? (
                <NavLink to={PrintAnalyticRouteConstants.BUSCAR_ESTABLECIMIENTO} style={{ textDecoration: 'none', marginTop: '0' }}>
                  {({ isActive }) =>
                    isActive ? (
                      <Button
                        color="neutral"
                        variant="contained"
                        fullWidth
                        sx={{ justifyContent: 'flex-start', boxShadow: 'none', textTransform: 'none' }}>
                        Imprimir
                      </Button>
                    ) : (
                      <Button color="neutral" variant="text" fullWidth sx={{ justifyContent: 'flex-start', textTransform: 'none' }}>
                        Imprimir
                      </Button>
                    )
                  }
                </NavLink>
              ) : null} */}

              {/* CONTRL */}
              {/* {auth.isAllowed([Actions.READ_PAPERMONEYRECORD]) ? (
                <NavLink to={Control.CONTROL} style={{ textDecoration: 'none', marginTop: '0' }}>
                  {({ isActive }) =>
                    isActive ? (
                      <Button
                        color="neutral"
                        variant="contained"
                        fullWidth
                        sx={{ justifyContent: 'flex-start', boxShadow: 'none', textTransform: 'none' }}>
                        Control
                      </Button>
                    ) : (
                      <Button color="neutral" variant="text" fullWidth sx={{ justifyContent: 'flex-start', textTransform: 'none' }}>
                        Control
                      </Button>
                    )
                  }
                </NavLink>
              ) : null} */}

              {auth.isAllowed([Actions.READ_ROL]) ? (
                <NavLink to={AdminRouteConstants.ROL_BUSCAR} style={{ textDecoration: 'none', marginTop: '0' }}>
                  {({ isActive }) =>
                    isActive ? (
                      <Button
                        color="neutral"
                        variant="contained"
                        fullWidth
                        sx={{ justifyContent: 'flex-start', boxShadow: 'none', textTransform: 'none' }}>
                        Roles
                      </Button>
                    ) : (
                      <Button color="neutral" variant="text" fullWidth sx={{ justifyContent: 'flex-start', textTransform: 'none' }}>
                        Roles
                      </Button>
                    )
                  }
                </NavLink>
              ) : null}
              {auth.isAllowed([Actions.READ_USER]) ? (
                <NavLink to={AdminRouteConstants.USUARIO_BUSCAR} style={{ textDecoration: 'none', marginTop: '0' }}>
                  {({ isActive }) =>
                    isActive ? (
                      <Button
                        color="neutral"
                        variant="contained"
                        fullWidth
                        sx={{ justifyContent: 'flex-start', boxShadow: 'none', textTransform: 'none' }}>
                        Usuarios
                      </Button>
                    ) : (
                      <Button color="neutral" variant="text" fullWidth sx={{ justifyContent: 'flex-start', textTransform: 'none' }}>
                        Usuarios
                      </Button>
                    )
                  }
                </NavLink>
              ) : null}
              {auth.isAllowed([Actions.READ_HISTORIC]) ? (
                <NavLink
                  to={
                    isUserAdminOrLegalization || validatorEstablishment
                      ? HistoricalRouteConstants.BUSCAR_ESTABLECIMIENTO
                      : HistoricalRouteConstants.BUSCAR
                  }
                  style={{ textDecoration: 'none', marginTop: '0' }}>
                  {({ isActive }) =>
                    isActive ? (
                      <Button
                        color="neutral"
                        variant="contained"
                        fullWidth
                        sx={{ justifyContent: 'flex-start', boxShadow: 'none', textTransform: 'none' }}>
                        Histórico
                      </Button>
                    ) : (
                      <Button color="neutral" variant="text" fullWidth sx={{ justifyContent: 'flex-start', textTransform: 'none' }}>
                        Histórico
                      </Button>
                    )
                  }
                </NavLink>
              ) : null}
              {auth.isAllowed([Actions.UPLOAD_DICTIONARIES]) ? (
                <NavLink to={AdminRouteConstants.DICCIONARIOS_CARGA} style={{ textDecoration: 'none', marginTop: '0' }}>
                  {({ isActive }) =>
                    isActive ? (
                      <Button
                        color="neutral"
                        variant="contained"
                        fullWidth
                        sx={{ justifyContent: 'flex-start', boxShadow: 'none', textTransform: 'none', textAlign: 'left' }}>
                        Carga de diccionarios
                      </Button>
                    ) : (
                      <Button color="neutral" variant="text" fullWidth sx={{ justifyContent: 'flex-start', textTransform: 'none' }}>
                        Carga de diccionarios
                      </Button>
                    )
                  }
                </NavLink>
              ) : null}
            </>
          </Stack>
        </ThemeProvider>
      </Box>
    </Grid>
  );
};
