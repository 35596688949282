import { Container, Grid } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CustomFooter from '../components/CustomFooter';
import Navbar from '../components/Navbar';
import { SidebarNav } from '../components/SidebarNav';
import { Actions } from '../constants/Actions';
import { EditRolPage } from '../pages/admin/roles/editRol/EditRolPage';
import { SearchRolesPage } from '../pages/admin/roles/searchRoles/SearchRolesPage';
import { EditUserPage } from '../pages/admin/users/editUser/EditUserPage';
import { SearchUsersPage } from '../pages/admin/users/searchUsers/SearchUsersPage';
import { AnalyticalEstablishmentPage } from '../pages/analytical/analyticalEstablishment/AnalyticalEstablishmentPage';
import AnalyticalPage from '../pages/analytical/analyticals/AnalyticalPage';
import { AnalyticDetailPage } from '../pages/analytical/AnalyticDetail/AnalyticDetailPage';
import { AnalyticalStepOnePage } from '../pages/analytical/stepOne/AnalyticalStepOnePage';
import { AnalyticalStepThreePage } from '../pages/analytical/stepThree/AnalyticalStepThreePage';
import { AnalyticalStepTwoPage } from '../pages/analytical/stepTwo/AnalyticalStepTwoPage';
import { CurriculumDetailPage } from '../pages/curriculum/curriculumDetail/CurriculumDetailPage';
import { CurriculumPage } from '../pages/curriculum/curriculumsTable/CurriculumPage';
import { CurriculumAddNewPage } from '../pages/curriculum/curriculumStepOne/CurriculumAddNewPage';
import { CurriculumStepTwoPage } from '../pages/curriculum/curriculumStepTwo/CurriculumStepTwoPage';
import { EstablishmentPage } from '../pages/establishments/establishmentTable/EstablishmentPage';
import MainPage from '../pages/MainPage';
import NotFoundPage from '../pages/NotFoundPage';
import { ProtectedRoute } from './ProtectedRoute';
import NotificationPage from '../pages/notification/NotificationPage';
import { HistoricalSearchPage } from '../pages/historical/search/HistoricalSearchPage';
import { CurriculumEstablishmentPage } from '../pages/curriculum/curriculumDetail/curriculumEstablishment/CurriculumEstablishmentPage';
import { HistoricalEstablishmentPage } from '../pages/historical/historicalEstablishment/HistoricalEstablishmentPage';
import UploadDictionariesPage from '../pages/admin/carga/UploadDictionariesPage';

export const DashboardRoutes = () => {
  return (
    <>
      <Navbar />
      <Container maxWidth="xl">
        <Routes>
          <Route
            path="/*"
            element={
              <Grid container>
                <Grid item xs={2} mt={2}>
                  <SidebarNav />
                </Grid>
                <Grid item xs={10}>
                  <Routes>
                    <Route path="analiticos">
                      <Route
                        path="buscar"
                        element={
                          <ProtectedRoute page={<AnalyticalPage />} requestedPermissions={[Actions.READ_ANALYTIC]}></ProtectedRoute>
                        }></Route>
                      <Route
                        path="buscar/:id/:name"
                        element={
                          <ProtectedRoute page={<AnalyticalPage />} requestedPermissions={[Actions.READ_ANALYTIC_GROUPED]}></ProtectedRoute>
                        }></Route>
                      <Route
                        path="buscarAgrupado"
                        element={
                          <ProtectedRoute
                            page={<AnalyticalEstablishmentPage />}
                            requestedPermissions={[Actions.READ_ANALYTIC_GROUPED]}></ProtectedRoute>
                        }></Route>
                      <Route
                        path="detalle/:id"
                        element={
                          <ProtectedRoute page={<AnalyticDetailPage />} requestedPermissions={[Actions.READ_ANALYTIC]}></ProtectedRoute>
                        }></Route>
                      <Route path="editar">
                        <Route
                          path="paso1/:id"
                          element={
                            <ProtectedRoute
                              page={<AnalyticalStepOnePage />}
                              requestedPermissions={[Actions.UPDATE_ANALYTIC]}></ProtectedRoute>
                          }></Route>
                        <Route
                          path="paso1/nuevo"
                          element={
                            <ProtectedRoute
                              page={<AnalyticalStepOnePage />}
                              requestedPermissions={[Actions.CREATE_ANALYTIC, Actions.UPDATE_ANALYTIC]}></ProtectedRoute>
                          }></Route>
                        <Route
                          path="paso2/:id"
                          element={
                            <ProtectedRoute
                              page={<AnalyticalStepTwoPage />}
                              requestedPermissions={[Actions.UPDATE_ANALYTIC]}></ProtectedRoute>
                          }></Route>
                        <Route
                          path="paso3/:id"
                          element={
                            <ProtectedRoute
                              page={<AnalyticalStepThreePage />}
                              requestedPermissions={[Actions.UPDATE_ANALYTIC]}></ProtectedRoute>
                          }></Route>
                      </Route>
                    </Route>
                    <Route path="/historico">
                      <Route
                        path="establecimientos"
                        element={
                          <ProtectedRoute
                            page={<HistoricalEstablishmentPage />}
                            requestedPermissions={[Actions.READ_PRINT, Actions.PRINT_ANALYTIC, Actions.VALIDATE_PRINT]}></ProtectedRoute>
                        }></Route>
                      <Route
                        path="buscar"
                        element={
                          <ProtectedRoute requestedPermissions={[Actions.READ_HISTORIC]} page={<HistoricalSearchPage />}></ProtectedRoute>
                        }></Route>
                      <Route
                        path="buscar/:id/:name"
                        element={
                          <ProtectedRoute requestedPermissions={[Actions.READ_HISTORIC]} page={<HistoricalSearchPage />}></ProtectedRoute>
                        }></Route>
                      <Route
                        path="detalle/:id"
                        element={
                          <ProtectedRoute page={<AnalyticDetailPage />} requestedPermissions={[Actions.READ_ANALYTIC]}></ProtectedRoute>
                        }></Route>
                    </Route>
                    <Route path="/admin">
                      <Route path="usuarios">
                        <Route
                          path="buscar"
                          element={
                            <ProtectedRoute page={<SearchUsersPage />} requestedPermissions={[Actions.READ_USER]}></ProtectedRoute>
                          }></Route>
                        <Route
                          path="editar"
                          element={
                            <ProtectedRoute page={<EditUserPage />} requestedPermissions={[Actions.CREATE_USER]}></ProtectedRoute>
                          }></Route>
                        <Route
                          path="editar/:id"
                          element={
                            <ProtectedRoute page={<EditUserPage />} requestedPermissions={[Actions.UPDATE_USER]}></ProtectedRoute>
                          }></Route>
                      </Route>
                      <Route path="roles">
                        <Route
                          path="buscar"
                          element={<ProtectedRoute page={<SearchRolesPage />} requestedPermissions={[Actions.READ_ROL]}></ProtectedRoute>}
                        />
                        <Route
                          path="editar"
                          element={<ProtectedRoute page={<EditRolPage />} requestedPermissions={[Actions.CREATE_ROL]}></ProtectedRoute>}
                        />
                        <Route
                          path="editar/:id"
                          element={<ProtectedRoute page={<EditRolPage />} requestedPermissions={[Actions.UPDATE_ROL]}></ProtectedRoute>}
                        />
                      </Route>
                      <Route path="carga">
                        <Route
                          path="diccionarios"
                          element={
                            <ProtectedRoute
                              page={<UploadDictionariesPage />}
                              requestedPermissions={[Actions.UPLOAD_DICTIONARIES]}></ProtectedRoute>
                          }
                        />
                      </Route>
                    </Route>
                    <Route path="planEstudio">
                      <Route
                        path="establecimientos"
                        element={
                          <ProtectedRoute
                            page={<CurriculumEstablishmentPage />}
                            requestedPermissions={[Actions.READ_PRINT, Actions.PRINT_ANALYTIC, Actions.VALIDATE_PRINT]}></ProtectedRoute>
                        }></Route>
                      <Route
                        path="buscar"
                        element={
                          <ProtectedRoute
                            page={<CurriculumPage />}
                            requestedPermissions={[Actions.READ_GLOBAL_STUDYPLAN, Actions.READ_STUDYPLAN]}></ProtectedRoute>
                        }></Route>
                      <Route
                        path="buscar/:id/:name"
                        element={
                          <ProtectedRoute
                            page={<CurriculumPage />}
                            requestedPermissions={[Actions.READ_GLOBAL_STUDYPLAN, Actions.READ_STUDYPLAN]}></ProtectedRoute>
                        }></Route>
                      <Route
                        path="detalle/:id"
                        element={
                          <ProtectedRoute
                            page={<CurriculumDetailPage />}
                            requestedPermissions={[Actions.READ_GLOBAL_STUDYPLAN, Actions.READ_STUDYPLAN]}></ProtectedRoute>
                        }></Route>
                      <Route path="editar">
                        <Route
                          path="paso1/nuevo"
                          element={
                            <ProtectedRoute
                              page={<CurriculumAddNewPage />}
                              requestedPermissions={[
                                Actions.ASSOCIATE_STUDYPLAN,
                                Actions.CREATE_STUDYPLAN,
                                Actions.UPDATE_STUDYPLAN
                              ]}></ProtectedRoute>
                          }></Route>
                        <Route
                          path="paso1/:id"
                          element={
                            <ProtectedRoute
                              page={<CurriculumAddNewPage />}
                              requestedPermissions={[
                                Actions.ASSOCIATE_STUDYPLAN,
                                Actions.CREATE_STUDYPLAN,
                                Actions.UPDATE_STUDYPLAN
                              ]}></ProtectedRoute>
                          }></Route>
                        <Route
                          path="paso2/:id"
                          element={
                            <ProtectedRoute
                              page={<CurriculumStepTwoPage />}
                              requestedPermissions={[
                                Actions.ASSOCIATE_STUDYPLAN,
                                Actions.CREATE_STUDYPLAN,
                                Actions.UPDATE_STUDYPLAN
                              ]}></ProtectedRoute>
                          }></Route>
                      </Route>
                    </Route>
                    <Route path="/seleccionarEstablecimiento" element={<EstablishmentPage />}></Route>

                    <Route
                      path="/notificaciones"
                      element={<ProtectedRoute page={<NotificationPage />} requestedPermissions={null}></ProtectedRoute>}
                    />

                    <Route path="/" element={<ProtectedRoute page={<MainPage />} requestedPermissions={null}></ProtectedRoute>} />
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </Grid>
                <br />
                <br />
              </Grid>
            }
          />
        </Routes>
      </Container>
      <CustomFooter />
    </>
  );
};
