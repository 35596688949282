export const Actions = {
  CREATE_ROL: 'create:rol',
  READ_ROL: 'read:rol',
  UPDATE_ROL: 'update:rol',
  DELETE_ROL: 'delete:rol',
  CREATE_USER: 'create:user',
  READ_USER: 'read:user',
  UPDATE_USER: 'update:user',
  DELETE_USER: 'delete:user',
  CREATE_ANALYTIC: 'create:analitic',
  READ_ANALYTIC: 'read:analitic',
  READ_ANALYTIC_GROUPED: 'read:establishmentAnalitic',
  UPDATE_ANALYTIC: 'update:analitic',
  DELETE_ANALYTIC: 'action:cancelAnalitic',
  OBSERVATE_ANALYTIC: 'action:observateAnalitic',
  SIGN_ANALYTIC: 'action:signAnalitic',
  VALIDATE_ANALYTIC: 'action:validateAnalitic',
  CREATE_STUDYPLAN: 'create:studyPlan',
  READ_STUDYPLAN: 'read:studyPlan',
  READ_GLOBAL_STUDYPLAN: 'read:globalStudyPlan',
  UPDATE_STUDYPLAN: 'update:studyPlan',
  DELETE_STUDYPLAN: 'delete:StudyPlan',
  APPROVE_STUDYPLAN: 'action:aproveStudyPlan',
  OBSERVE_STUDYPLAN: 'action:observeStudyPlan',
  ASSOCIATE_STUDYPLAN: 'action:associateStudyPlan',
  DISASSOCIATE_STUDYPLAN: 'action:disassociateStudyPlan',
  MASSIVE_STUDYPLAN: 'create:massiveStudyPlan',
  CREATE_MASSIVE_ESTABLISHMENT: 'create:massiveEstablishment',
  SELECT_ESTABLISHMENT: 'read:establishment',
  READ_PRINT: 'read:analiticPrint',
  PRINT_ANALYTIC: 'action:analiticPrint',
  VALIDATE_PRINT: 'action:validatePrint',
  LEGALIZE_ANALITIC: 'action:LegalizeAnalitic',
  CREATE_PAPERMONEYRECORD: 'create:paperMoneyRecord',
  READ_PAPERMONEYRECORD: 'read:paperMoneyRecord',
  UPDATE_PAPERMONEYRECORD: 'update:paperMoneyRecord',
  DELETE_PAPERMONEYRECORD: 'delete:paperMoneyRecord',
  SEND_ANALYTIC: 'action:sendAnalytics',
  READ_HISTORIC: 'read:historic',
  CANCEL_LEGALIZED_ANALYTIC: 'action:cancelLegalizedAnalytic',
  UNSUSCRIBE_ANALYTIC: 'action:unsuscribeAnalytic',
  ASSOCIATE_STATEESTABLISHMENT: 'read:stateManagementeStablishment',
  READ_NATION_STATES: 'read:nationStates',
  UPLOAD_DICTIONARIES: 'action:uploadDictionary'
};

export const Roles = {
  CARGA: 1,
  CARGAFIRMA: 2,
  FIRMAAUTORIZADA: 3,
  SUPERADMIN: 6,
  ADMIN: 5,
  LEGALIZATION: 4,
  VALIDADOR: 7
};
