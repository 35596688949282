import { Alert, AlertTitle, Button, Grid, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect, useRef, useContext } from 'react';
import { CargaContext } from '../../../context/admin/carga/CargaContext';
import { MyButton } from '../../../styles/Styles';

const UploadButtons = () => {
  const {
    setStudyPlanFile,
    setCurricularBoxFile,
    uploadAllFiles,
    studyPlanFile,
    curricularBoxFile,
    invalidStudyPlanRecords,
    invalidCurricularBoxRecords,
    handleExcelDownload
  } = useContext(CargaContext);
  const studyPlanInput = useRef(null);
  const curricularBoxInput = useRef(null);

  useEffect(() => {
    if (studyPlanInput.current) {
      // Crea listener para escuchar cuando el user agrega archivos al source data:
      studyPlanInput.current.addEventListener('change', handleStudyPlanFileChange);
    }
    return () => {
      // Elimina el listener al desmontar el componente
      if (studyPlanInput.current) {
        studyPlanInput.current.removeEventListener('change', handleStudyPlanFileChange);
      }
    };
  }, [studyPlanInput]);

  useEffect(() => {
    if (curricularBoxInput.current) {
      // Crea listener para escuchar cuando el user agrega archivos al source data:
      curricularBoxInput.current.addEventListener('change', handleCurricularBoxFileChange);
    }
    return () => {
      // Elimina el listener al desmontar el componente
      if (curricularBoxInput.current) {
        curricularBoxInput.current.removeEventListener('change', handleCurricularBoxFileChange);
      }
    };
  }, [studyPlanInput]);

  const handleStudyPlanFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setStudyPlanFile(event.target.files[0]);
    }
  };

  const handleCurricularBoxFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setCurricularBoxFile(event.target.files[0]);
    }
  };

  /**
   * Dispara la subida de archivos y limpia los campos de seleccion de archivo
   * de plan de estudio y caja curricular luego de haberlos subido exitosamente.
   */
  const handleUpload = () => {
    const response = uploadAllFiles();
    if (response) {
      if (studyPlanFile) setStudyPlanFile(null);
      if (curricularBoxFile) setCurricularBoxFile(null);
    }
  };

  return (
    <Grid container direction="column">
      <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', my: 2 }}>
        <Typography variant="h6" color="initial">
          Diccionario de planes de estudio
        </Typography>
        <MyButton component="label" role={undefined} variant="contained" tabIndex={-1}>
          Cargar diccionario
          <input ref={studyPlanInput} type="file" accept=".xlsx,.xls" hidden onClick={handleStudyPlanFileChange} />
        </MyButton>
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6" color="initial">
          Diccionario de espacios curriculares
        </Typography>
        <MyButton component="label" role={undefined} variant="contained" tabIndex={-1}>
          Cargar diccionario
          <input ref={curricularBoxInput} type="file" accept=".xlsx,.xls" hidden onClick={handleCurricularBoxFileChange} />
        </MyButton>
      </Grid>
      <MyButton variant="contained" startIcon={<CloudUploadIcon />} onClick={handleUpload} disabled={!studyPlanFile && !curricularBoxFile}>
        Subir archivos
      </MyButton>
      {!!invalidStudyPlanRecords.length || !!invalidCurricularBoxRecords.length ? (
        <Alert
          severity="error"
          sx={{ mt: 2, alignItems: 'center' }}
          action={
            <Button
              size="small"
              color="inherit"
              sx={{ fontWeight: 700, minWidth: 'max-content' }}
              variant="outlined"
              onClick={handleExcelDownload}>
              Descargar listados
            </Button>
          }>
          <AlertTitle>Completado con errores en algunos de los registros</AlertTitle>
          Puede descargar un listado de los mismos presionando el botón correspondiente. En el excel descargable encontrará una hoja para
          los registros inválidos de plan de estudios y otra para los registros inválidos de espacios curriculares.
        </Alert>
      ) : null}
    </Grid>
  );
};

export default UploadButtons;
