import { Container, Grid, Typography } from '@mui/material';
import UploadButtons from './UploadButtons';
import CargaState from '../../../context/admin/carga/CargaState';

const UploadDictionariesPage = () => {
  return (
    <CargaState>
      <Container sx={{ mt: 4 }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Typography variant="h3" color="initial">
              Carga de diccionarios
            </Typography>
          </Grid>
        </Grid>
        <UploadButtons />
      </Container>
    </CargaState>
  );
};

export default UploadDictionariesPage;
